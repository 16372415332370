import "form-request-submit-polyfill"
import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  // Actions
  submit(event) {
    const form = event.target.form || event.target.closest("form")
    if (form) form.requestSubmit()
  }

  submitByKeyboard(event) {
    const commandEnterPressed = event.key == "Enter" && (event.metaKey || event.ctrlKey)
    if (!commandEnterPressed) return

    const form = event.target.form || event.target.closest("form")
    if (!form) return

    event.preventDefault()
    form.requestSubmit()
  }

  cancel(event) {
    event.preventDefault()
    Turbo.visit(window.location)
  }
}
