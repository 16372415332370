import ApplicationController from './application_controller'
import { get } from 'axios'

export default class extends ApplicationController {
  static targets = ['date', 'container']
  static values = {
    url: String,
  }

  initialize() {
    this.showScheduledTasks()
    document.addEventListener(
      'datetime:change',
      this.showScheduledTasks.bind(this),
    )
  }

  showScheduledTasks() {
    if (this.dateTarget.value) {
      const requestURL = `${this.urlValue}?due_date=${this.dateTarget.value}`

      get(requestURL).then(({ data }) => {
        this.containerTarget.innerHTML = data
      })
    }
  }
}
