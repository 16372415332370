import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  initialize() {
    const editor = this.element.editor

    this.element.addEventListener("trix-focus", () => {
      if (this.shouldAttachSignature && this.signature) {
        editor.insertHTML(`<br><br>${this.signature}`)
        
        setTimeout(() => {
          editor.setSelectedRange(0)
        }, 10)
      }
    })
  }

  // Private
  get signature() {
    return this.data.get("signature")
  }

  get shouldAttachSignature() {
    return this.element.value === ""
  }
}
