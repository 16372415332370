import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkbox" ]
  static values = { contactMethods: Array }

  update({ target }) {
    if (this.#isNotifiableVia(target.value)) {
      this.checkboxTarget.removeAttribute("hidden")
    } else {
      this.checkboxTarget.addAttribute("hidden")
    }
  }

  #isNotifiableVia(contactMethod) {
    return this.contactMethodsValue.includes(contactMethod)
  }
}
