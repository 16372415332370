import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  connect() {
    this.element.style.transition = "opacity 1s ease";

    setTimeout(() => {
      this.element.style.opacity = 0;
    }, 2600)
  }
}
