import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ 'status' ]

  // Actions
  toggleMessageStatus(e) {
    e.preventDefault()

    const currentValue = this.statusTarget.value
    this.statusTarget.value = currentValue === 'pending' ? 'deferred' : 'pending'
    return this.statusTarget.value
  }
}
