import ApplicationController from './application_controller'
import { post } from 'axios'

export default class extends ApplicationController {
  connect() {
    this.validate()
  }

  get validationURL() {
    return this.data.get('validationUrl')
  }

  get email() {
    return this.data.get('value')
  }

  // Actions
  validate() {
    post(this.validationURL, { email: this.email }).then((response) => {
      if (response.data) {
        this.element.innerHTML = response.data
        this.element.classList.remove('hidden')
      }
    })
  }
}
