import { Controller } from '@hotwired/stimulus'
import { throttle } from 'lodash'

export default class extends Controller {
  initialize() {
    this.toggle = throttle(this.toggle)
    this.toggle()
  }

  // Actions

  toggle() {
    this.element.classList.toggle(this.data.get('class'), this.isScrolled)
  }

  // Private

  get isScrolled() {
    return window.scrollY > this.scrollThreshold
  }

  get scrollThreshold() {
    return parseInt(this.data.get('scrollThreshold') || 0)
  }
}
