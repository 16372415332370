import ApplicationController from './application_controller'

export default class extends ApplicationController {
  requestCSV(e) {
    e.preventDefault()
    window.open(this.buildRequestURL(), '_blank')
  }

  buildRequestURL() {
    const [ endpoint, params ] = window.location.href.split('?')
    return `${endpoint}.csv?${params}`
  }
}
