import ApplicationController from './application_controller'
import { subscribeTo } from '../helpers'

export default class extends ApplicationController {
  static values = { identifier: String }
  static targets = ['percentage']

  connect() {
    this.subscribe()
  }

  update({ value }) {
    this.percentageTarget.innerHTML = value
    if (value === '100%') Turbo.visit(window.location)
  }

  disconnect() {
    this.subscription?.unsubscribe()
  }

  // Private
  async subscribe() {
    this.subscription = await subscribeTo(
      { channel: 'ReportsChannel', id: this.identifierValue },
      { received: this.update.bind(this) },
    )
  }
}
