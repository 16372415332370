import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ 'reflection' ]

  updateReflection(e) {
    if (e.target.value.length > 0) {
      this.reflectionTarget.innerText = e.target.value
    }
  }
}
