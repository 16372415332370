import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ 'input' ]

  // Actions
  reset() {
    this.inputTarget.value = ''
  }
}
