require('@rails/ujs').start()
import '@hotwired/turbo-rails'
require('@rails/activestorage').start()
import './channels'
import 'bootstrap/dist/js/bootstrap'
import jQuery from 'jquery'
window.jQuery = jQuery

import './controllers'
import LocalTime from 'local-time'
import '@fortawesome/fontawesome-free/js/all'

LocalTime.start()

import 'trix'

import '@rails/actiontext'
// import { AttachmentUpload } from '@rails/actiontext/app/javascript/actiontext/attachment_upload'

// addEventListener('trix-attachment-add', (event) => {
//   const { attachment, target } = event

//   if (attachment.file) {
//     const upload = new AttachmentUpload(attachment, target)
//     upload.start()
//   }
// })

import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  dsn:
    'https://be24af85b86240cf8ba53d72c96a1e1a@o130903.ingest.sentry.io/1637434',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})
