import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [ "field", "status" ]

  initialize() {
    this.toggleFields()
  }

  changeStatus(e) {
    if (e.target.value === "employee") {
      this.fields.forEach((field) => field.classList.add("hidden"))
    } else {
      this.fields.forEach((field) => field.classList.remove("hidden"))
    }
  }

  // Private
  toggleFields() {
    if (this.statusTarget.value == "employee") {
      this.fields.forEach((field) => field.classList.add("hidden"))
    } else {
      this.fields.forEach((field) => field.classList.remove("hidden"))
    }
  }

  get fields() {
    return [...this.fieldTargets]
  }
}
