import ApplicationController from './application_controller'
import { get } from 'axios'
import Favico from 'favico.js'

export default class extends ApplicationController {
  static targets = [ 'badge' ]
  static values = {
    unreadMessagesUrl: String,
    messagesUrl: String
  }

  initialize() {
    this.favicon = new Favico({
      animation: "none"
    })
  }

  connect() {
    document.addEventListener('unreads:change', (e) => {
      this.data.set('count', `${e.detail.value}`)
      this.updateBadge()
      this.updateUrl()
    })

    this.fetchUnreadsCount()
    this.updateBadge()
    this.updateUrl()

    this.timerId = setInterval(() => this.fetchUnreadsCount(), 10 * 1000)
  }

  disconnect() {
    clearInterval(this.timerId)
  }

  get unreadsCount() {
    return parseInt(this.data.get('count') || 0)
  }

  get badge() {
    return this.badgeTarget
  }

  updateBadge() {
    if (this.unreadsCount > 0) {
      this.badge.innerHTML = this.unreadsCount
      this.favicon.badge(this.unreadsCount)
      this.badge.classList.remove('hidden')
    } else {
      this.favicon.reset()
      this.badge.classList.add('hidden')
    }
  }

  updateUrl() {
    if (this.unreadsCount > 0) {
      this.element.href = this.unreadMessagesUrlValue
    } else {
      this.element.href = this.messagesUrlValue
    }
  }

  fetchUnreadsCount() {
    return get('/my/unreads').then((result) => {
      document.dispatchEvent(new CustomEvent('unreads:change', { detail: { value: result.data }, bubbles: true, cancelable: false }))
    })
  }
}
