import { Controller } from '@hotwired/stimulus'
import { createPopper } from '@popperjs/core'

export default class extends Controller {
  initialize() {
    this.element.addEventListener('mouseover', () => {
      const pop = this.createTooltipPop(this.element, this.data.get('copy'))
      this.element.addEventListener(
        'mouseout',
        () => {
          pop.classList.remove('pop--visible')
          setTimeout(() => {
            pop.remove()
          }, 100)
        },
        { once: true },
      )
    })
  }

  createTooltipPop(el, copy) {
    const template = `
    <div class="pop">
      ${copy}
      <div class="pop__arrow"></div>
    </div>
    `

    const pop = new DOMParser().parseFromString(template, 'text/html')
    const definitionPop = this.element.appendChild(pop.body.firstChild)

    new createPopper(el, definitionPop, {
      onFirstUpdate: function (data) {
        definitionPop.classList.add('pop--visible')
      },
    })

    return definitionPop
  }
}
