import ApplicationController from './application_controller'
import { cancel, nextFrame } from '../helpers'

export default class extends ApplicationController {
  static targets = ['item']

  connect() {
    this.summaryElement?.setAttribute('aria-haspopup', 'menu')
    this.update()
  }

  disconnect() {
    this.close()
  }

  update() {
    this.focusInitial()
    this.removeTabstops()
  }

  navigate(event) {
    switch (event.key) {
      case 'Escape':
        cancel(event)
        this.closeAndRestoreFocus()
        break
    }
  }

  closeAndRestoreFocus() {
    this.close()
    this.summaryElement.focus()
  }

  closeOnClickOutside({ target }) {
    if (this.element.contains(target)) return

    this.close()
  }

  close() {
    this.element.open = false
  }

  focusExclusively(element) {
    if (element) {
      this.removeTabstops()
      element.focus()
      element.removeAttribute('tabindex')
    }
  }

  async focusInitial() {
    await nextFrame()

    if (this.hasItems) {
      this.focusExclusively(this.initialItem)
    }
  }

  get initialItem() {
    return this.itemTargets.find(wantsFocus) || this.items[0]
  }

  removeTabstops() {
    this.itemTargets.forEach((target) => target.setAttribute('tabindex', '-1'))
  }

  get summaryElement() {
    return this.element.querySelector('summary')
  }
}

function wantsFocus(element) {
  return element.getAttribute('autofocus')
}
