import ApplicationController from "./application_controller"
import Cookies from "js-cookie"

export default class extends ApplicationController {
  initialize() {
    this.toggle()
  }

  // Actions
  hide() {
    Cookies.set(this.cacheKey, { expires: 1 })
    this.toggle()
  }

  // Private
  toggle() {
    this.classList.toggle("hidden", !this.visibility)
  }

  get cacheKey() {
    return `${this.identifier}_${this.data.get("name")}`
  }

  get visibility() {
    return !Cookies.get(this.cacheKey)
  }
}
