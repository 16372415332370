import ApplicationController from "./application_controller"
import { FetchRequest } from "@rails/request.js"
import { LocalForm } from "../lib/local_form"
import { delay } from "../helpers"

const SAVE_DELAY = 1000

export default class extends ApplicationController {
  static targets = ["form", "manualSaveRedirectLink", "discardLink", "discardButton", "restorationPrompt"]
  static values = { stopped: Boolean }

  initialize() {
    if (this.hasLocalDraft) this.restorationPromptTarget.hidden = false
  }

  connect() {
    this.stoppedValue = this.pageIsTurboPreview
  }

  disconnect() {
    this.stoppedValue = true
  }

  async saveAutomatically() {
    if (!this.saveScheduled) {
      this.saveScheduled = true
      await delay(SAVE_DELAY)
      this.saveScheduled = false
      this.saveDraft()
    }
  }

  async saveManually() {
    await this.createOrUpdateDraft()
    this.manualSaveRedirectLinkTarget.click()
  }

  // Queing and saving

  async saveDraft() {
    if (this.savingDraft) {
      this.pendingRequest = true
      return
    }

    this.savingDraft = true
    await this.createOrUpdateDraft()
    this.savingDraft = false

    if (this.pendingRequest) {
      this.pendingRequest = false
      this.saveDraft()
    }
  }

  async createOrUpdateDraft() {
    if (this.stoppedValue) return

    try {
      const response = await this.submitFormAsDraft()
      if (response.ok) {
        this.discardLocalDraft()
        if (this.isNewRecord) {
          const location = response.headers.get("Location")
          this.nextSubmitShouldUpdate(location)
          this.nextDiscardShouldDelete(location)
        }
        this.dispatch("save")
      } else {
        this.saveLocalDraft()
      }
    } catch (error) {
      this.saveLocalDraft()
    }
  }

  submitFormAsDraft() {
    // const request = new FetchRequest("post", this.formAction, { body: this.autodraftableFormData })
    // return request.perform()
  }

  // Submitting to actually send the email

  stopSavingDrafts() {
    this.stoppedValue = true
  }

  resumeSavingDrafts(event) {
    if (event.detail.success) {
      this.discardLocalDraft()
    } else {
      if (this.hasLocalDraft) this.displayLocalDraftAlert()
      this.stoppedValue = false
    }
  }

  // Manipulating form from creating a draft => updating a draft

  nextSubmitShouldUpdate(location) {
    this.formAction = location
    this.formMethod = "PUT"
  }

  nextDiscardShouldDelete(location) {
    if (this.hasDiscardButtonTarget) {
      this.discardButtonTarget.form.action = location
      this.discardButtonTarget.hidden = false
      this.discardLinkTarget.hidden = true
    }
  }

  synthesizeFormMethod() {
    this.syntheticMethodInput = this.element.querySelector("input[name=_method]")
    if (!this.syntheticMethodInput) {
      this.syntheticMethodInput = document.createElement("input")
      this.syntheticMethodInput.type = "hidden"
      this.syntheticMethodInput.name = "_method"
      this.syntheticMethodInput.value = this.element.method
      this.element.append(this.syntheticMethodInput)
    }
  }

  // Local storage

  saveLocalDraft() {
    this.localForm.save()
    this.dispatch("save-local")
  }

  discardLocalDraft() {
    if (!this.hasLocalDraft) return
    this.localForm.discard()
    this.restorationPromptTarget.hidden = true
  }

  restoreLocalDraft() {
    if (!this.hasLocalDraft) return
    this.localForm.restore()
    this.localForm.discard()
    this.restorationPromptTarget.hidden = true
  }

  displayLocalDraftAlert() {
    alert("Your message has been saved, but not sent. It will stay saved on this page, but only on this device.")
  }

  get hasLocalDraft() {
    return this.localForm.isSaved
  }

  get localForm() {
    return new LocalForm(this.formTarget)
  }

  // Attributes

  get pageIsTurboPreview() {
    return document.documentElement.hasAttribute("data-turbo-preview")
  }

  get isNewRecord() {
    return this.formMethod.toUpperCase() == "POST"
  }

  get formAction() {
    return this.formTarget.action
  }

  set formAction(action) {
    this.formTarget.action = action
  }

  get formMethod() {
    if (!this.syntheticMethodInput) this.synthesizeFormMethod()
    return this.syntheticMethodInput.value
  }

  set formMethod(method) {
    if (!this.syntheticMethodInput) this.synthesizeFormMethod()
    this.syntheticMethodInput.value = method
  }

  get autodraftableFormData() {
    const formData = new FormData(this.formTarget)
    formData.set("autodraft", "true")
    formData.set("message[status]", "drafted")
    return formData
  }
}
