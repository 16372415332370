import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [ "primaryField", "alternativeField" ]

  initialize() {
    this.primaryFieldTarget.addEventListener("change", ({ target }) => {
      this.alternativeFieldTarget.toggleAttribute("hidden", target.value != this.triggerValue)
      this.alternativeFieldTarget.toggleAttribute("disabled", target.value != this.triggerValue)
      this.alternativeFieldTarget.focus()
    })

    setTimeout(() => {
      if (this.alternativeFieldTarget.value &&
          ![...this.primaryFieldTarget.options].map(el => el.value).includes(this.alternativeFieldTarget.value)) {
        this.primaryFieldTarget.value = this.triggerValue
        this.alternativeFieldTarget.removeAttribute("hidden")
        this.alternativeFieldTarget.removeAttribute("disabled")
      }
    }, 100)
  }

  get triggerValue() {
    return this.data.get("triggerValue")
  }
}
