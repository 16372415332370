import ApplicationController from './application_controller'
import { get } from 'axios'

export default class extends ApplicationController {
  connect() {
    get(this.templateURL).then((result) => {
      document.body.insertAdjacentHTML('beforeend', result.data)
    }).then(() => {
      this.element.dataset.toggle = 'modal'
      this.element.dataset.target = this.modalIdSelector
    }).then(() => {
      document.querySelector(this.modalIdSelector).classList.add('modal--csp')
    })
  }

  get templateURL() {
    return this.element.href
  }

  get modalIdSelector() {
    return `#${this.data.get('id')}_modal`
  }
}
