import ApplicationController from './application_controller'
import { autocomplete } from '@algolia/autocomplete-js'

export default class extends ApplicationController {
  static targets = [ 'input' ]
  static values  = { url: String, initialLabel: String }

  connect() {
    autocomplete({
      container: this.element,
      initialState: {
        query: this.initialLabelValue,
      },
      getSources: () => {
        return [
          {
            sourceId: 'autocompletables',
            getItems: this.getItems(),
            onSelect: this.onSelect(),
            templates: {
              item({ item }) {
                return item.name;
              },
            },
          }
        ];
      },
    });
  }

  onSelect() {
    return (event) => {
      this.inputTarget.value = event.item.id;
      console.log(event);
      event.setQuery(event.item.name);
    }
  }

  getItems() {
    return async ({ query }) => {
      const response = await fetch(`${this.urlValue}?query=${query}`);
      return await response.json();
    }
  }
}
