export * from './current_helpers'
export * from './cable_helpers'
export * from './debounce_helpers'
export * from './scroll_helpers'
export * from './element_helpers'

export function nextFrame() {
  return new Promise(requestAnimationFrame)
}

export function nextIdle() {
  return new Promise(window.requestIdleCallback || setTimeout)
}

export function delay(ms = 1) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
