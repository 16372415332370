import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [ "option" ]
  static classes = [ "option", "selectedOption" ]

  connect() {
    if (this.defaultOption) this.select({ target: this.defaultOption })
  }

  select({ target }) {
    console.log(target.closest(`.${this.optionClass}`))
    this.element.querySelectorAll(`.${this.selectedOptionClass}`).forEach((element) => element.classList.remove(this.selectedOptionClass))
    target.closest(`.${this.optionClass}`).classList.toggle(this.selectedOptionClass, target.checked)
  }

  get defaultOption() {
    return this.optionTargets.find((optionElement) => optionElement.querySelector("input[type='radio']").checked)
  }
}
