import ApplicationController from "./application_controller"
import formSerialize from "form-serialize"

export default class extends ApplicationController {
  static targets = [ "form" ]

  requestCSV(e) {
    e.preventDefault()
    window.open(this.buildRequestURL(), "_blank")
  }

  buildRequestURL() {
    const params = formSerialize(this.form)
    return `${this.form.action}.csv?${params}`
  }

  get form() {
    return this.formTarget
  }
}
