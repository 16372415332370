import ApplicationController from './application_controller'
import { install, uninstall } from '@github/hotkey'

export default class extends ApplicationController {
  connect() {
    install(this.element)
  }

  disconnect() {
    uninstall(this.element)
  }
}
