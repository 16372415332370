import ApplicationController from "./application_controller"
import moment from "moment-timezone"
import { current, nextFrame } from "../helpers"

require("jquery-ui/ui/widgets/datepicker")

export default class extends ApplicationController {
  static targets = [ "date", "hour", "minute", "source" ]

  initialize() {
    this.date = moment(this.dateTarget.value, "MM/DD/YYYY")
    if (current.user.timeZoneName) { this.date = this.date.tz(current.user.timeZoneName, true) }

    this.setupTimeSelect()
  }

  async connect() {
    this.$date.datepicker({
      onSelect: function() { this.updateSource() }.bind(this)
    }).datepicker("setDate", new Date(this.sourceTarget.dataset.datetime))

    await nextFrame()
    this.updateSource()
  }

  // Actions
  updateSourceFactory() {
    return this.updateSource.bind(this)
  }

  updateSource() {
    let datetime = moment(this.dateTarget.value, "MM/DD/YYYY")
    if (current.user.timeZoneName) { datetime = datetime.tz(current.user.timeZoneName, true) }

    datetime.hour(this.hourTarget.value)
    datetime.minute(this.minuteTarget.value)
    this.sourceTarget.value = datetime.toISOString()
    this.dispatch("change", { target: document })

    return false
  }

  // Private
  setupTimeSelect() {
    this.element.querySelector(".custom-select--hour").dataset.datetimeTarget = "hour"
    this.element.querySelector(".custom-select--hour").dataset.action = `change->${this.identifier}#updateSource`
    this.element.querySelector(".custom-select--minute").dataset.datetimeTarget = "minute"
    this.element.querySelector(".custom-select--minute").dataset.action = `change->${this.identifier}#updateSource`
  }

  get $date() {
    return jQuery(this.dateTarget)
  }
}
