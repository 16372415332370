import ApplicationController from './application_controller'
import { debounce, scrollToElement } from '../helpers'
import { get } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['input', 'results', 'result']
  static values = { url: String, selectedIndex: Number }
  static classes = ['show', 'selected', 'busy', 'empty']

  initialize() {
    this.attemptSearch = debounce(this.attemptSearch.bind(this), 300)
  }

  disconnect() {
    this.reset()
  }

  selectedIndexValueChanged() {
    this.addSelectionHighlight()
  }

  // Actions
  reset(event) {
    if (this.hasInputTarget) {
      this.inputTarget.value = this.resultsTarget.innerHTML = ''
    }

    if (this.hasShowClass && this.hasEmptyClass) {
      this.classList.remove(this.showClass, this.emptyClass)
    }

    this.abortPendingSearchRequest()
    if (this.element.contains(event?.target)) this.inputTarget.focus()
  }

  resetOnClickOutside(event) {
    if (!this.element.contains(event.target)) this.reset()
  }

  resetOnEscape(event) {
    if (event.key == 'Escape') {
      this.reset()
      this.element.focus()
    }
  }

  updateSelectionWithKeyboard(event) {
    switch (event.key) {
      case 'ArrowUp':
        cancel(event)
        this.selectPrevious()
        break
      case 'ArrowDown':
        cancel(event)
        this.selectNext()
        break
      case 'Enter':
        this.activateSelection()
        break
    }
  }

  // Private
  selectPrevious() {
    if (this.selectedIndexValue > 0) {
      this.removeSelectionHighlight()
      this.selectedIndexValue--
    }
  }

  selectNext() {
    if (this.selectedIndexValue < this.resultTargets.length - 1) {
      this.removeSelectionHighlight()
      this.selectedIndexValue++
    }
  }

  addSelectionHighlight() {
    if (this.selectedResultTarget) {
      this.selectedResultTarget.classList.add(this.selectedClass)
      scrollToElement(this.selectedResultTarget)
    }
  }

  removeSelectionHighlight() {
    this.selectedResultTarget?.classList.remove(this.selectedClass)
  }

  activateSelection() {
    this.selectedResultTargetLink?.click()
  }

  abortPendingSearchRequest() {
    this.abortController?.abort()
  }

  attemptSearch() {
    if (this.isQueryPresent) {
      this.performSearch()
    } else {
      this.reset()
    }
  }

  async performSearch() {
    this.abortPendingSearchRequest()
    this.abortController = new AbortController()
    const { signal } = this.abortController

    try {
      this.classList.add(this.busyClass)

      get(this.searchURL, {
        signal,
        headers: {
          'Content-Type': 'text/html',
        },
      })
        .then(({ response }) => response.text())
        .then((body) => {
          this.resultsTarget.innerHTML = body
        })

      this.openResults()
    } catch (error) {
      if (error.name != 'AbortError') throw error
      this.classList.remove(this.busyClass)
    }
  }

  openResults() {
    this.classList.remove(this.busyClass)
    this.classList.add(this.showClass)
    this.classList.toggle(this.emptyClass, this.resultTargets.length == 0)
    this.selectedIndexValue = 0
    this.addSelectionHighlight()
  }

  get selectedResultTarget() {
    return this.resultTargets[this.selectedIndexValue]
  }

  get selectedResultTargetLink() {
    const target = this.selectedResultTarget
    if (target?.matches('a[href]')) return target
    return target?.querySelector('a[href]')
  }

  get isQueryPresent() {
    return this.searchQuery.length > 0
  }

  get searchQuery() {
    return this.inputTarget.value.trim()
  }

  get searchURL() {
    const url = new URL(this.urlValue)
    url.searchParams.set('q', this.searchQuery)
    return url
  }
}

function cancel(event) {
  event.stopPropagation()
  event.preventDefault()
}
