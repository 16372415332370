import ApplicationController from "./application_controller"
import { get } from "axios"

export default class extends ApplicationController {
  static targets = [ "link", "sessionsDateFrom", "sessionsDateUntil" ]

  initialize() {
    this.linkTargets.forEach((el) => {
      el.addEventListener("click", this.loadProgram.bind(this))
    })
  }

  loadProgram(event) {
    event.preventDefault()

    const { target } = event
    const program = document.getElementById(target.dataset.programId)

    if (document.body.contains(program)) {
      program.remove()
      target.classList.remove("txt--bold")
    } else {
      get(this.buildProgramURL(target)).then(({ data }) => {
        target.closest("tr").insertAdjacentHTML("afterend", data)
      }).then(_ => target.classList.add("txt--bold"))
    }
  }

  buildProgramURL(programElement) {
    if (this.hasSessionsDateFromTarget && this.hasSessionsDateUntilTarget) {
      return `${programElement.dataset.url}?sessions_date_from=${this.sessionsDateFromTarget.value}&sessions_date_until=${this.sessionsDateUntilTarget.value}`
    } else {
      return programElement.dataset.url
    }
  }
}
