import ApplicationController from "./application_controller"
import { nextFrame } from "../helpers"

export default class extends ApplicationController {
  async connect() {
    this.autoresize()
  }

  async autoresize() {
    await nextFrame()
    this.element.style.height = 0
    const offset = this.element.offsetHeight - this.element.clientHeight
    this.element.style.height = `${this.element.scrollHeight + offset}px`
  }
}
