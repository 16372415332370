import ApplicationController from "./application_controller"
import moment from "moment-timezone"
import { current } from "../helpers"

require("jquery-ui/ui/widgets/datepicker")

export default class extends ApplicationController {
  static targets = [ "from", "until", "actionElement" ]

  initialize() {
    this.DATE_FORMAT = "MM/DD/YYYY"
    this.toggleActionElementDisability()
  }

  connect() {
    this.updateSource(this.fromTarget.dataset.daterangeSource)(this.fromTarget.value)
    jQuery(this.fromTarget).datepicker({
      onSelect: this.updateSource(this.fromTarget.dataset.daterangeSource).bind(this),
      create: this.updateSource(this.fromTarget.dataset.daterangeSource).bind(this),
      onClose: (selectedDate) => jQuery(this.untilTarget).datepicker("option", "minDate", selectedDate)
    })

    this.updateSource(this.untilTarget.dataset.daterangeSource)(this.untilTarget.value)
    jQuery(this.untilTarget).datepicker({
      onSelect: this.updateSource(this.untilTarget.dataset.daterangeSource).bind(this),
      create: this.updateSource(this.untilTarget.dataset.daterangeSource).bind(this),
      onClose: (selectedDate) => jQuery(this.fromTarget).datepicker("option", "maxDate", selectedDate)
    })
  }

  // Actions
  updateSource(name) {
    return (value) => {
      let datetime = moment(value, this.DATE_FORMAT)
      if (current.user.timeZoneName) { datetime = datetime.tz(current.user.timeZoneName, true) }

      if (name.includes("from")) {
        datetime = datetime.startOf("day")
      } else {
        datetime = datetime.endOf("day")
      }

      this.findSourceByName(name).value = datetime.toISOString()
      this.toggleActionElementDisability()

      return false
    }
  }

  // Private
  findSourceByName(name) {
    return document.querySelector(`input[name="${name}"]`)
  }

  toggleActionElementDisability() {
    if (this.hasActionElementTarget) {
      this.actionElementTarget.disabled = (!this.fromTarget.value || !this.untilTarget.value)
    }
  }
}
