import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [ "source" ]
  static classes = [ "supported", "success" ]

  initialize() {
    if (document.queryCommandSupported("copy")) {
      this.element.classList.add(this.supportedClass)
    }
  }

  // Actions
  copy(event) {
    const { target } = event
    event.preventDefault()

    this.sourceTarget.select()
    document.execCommand("copy")

    this.element.classList.add(this.successClass)
    target.innerText = "Copied"

    setTimeout(() => {
      this.sourceTarget.blur()
      this.element.classList.remove(this.successClass)
      target.innerText = "Copy"
    }, 2200)
  }
}
