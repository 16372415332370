import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    this.element.addEventListener('click', this.toggleExpandable.bind(this))
  }

  // Actions
  toggleExpandable() {
    document.querySelector(this.data.get('expandable')).classList.toggle('expanded')
  }
}
